import React from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
import Layout from "../../components/Layout"

import styled from "styled-components"
import { LinkCta } from "../../components/Button"

const SectionHeading = styled.h2`
  font-size: 1.75rem;
  line-height: 1.2;
  font-weight: 200;
  margin-bottom: 20px;

  text-align: ${props => (props.center ? "center" : "left")};

  @media (min-width: 576px) {
    font-size: 2rem;
  }

  @media (min-width: 768px) {
    font-size: 2.25rem;
  }

  @media (min-width: 992px) {
    font-size: 2.75rem;
  }

  @media (min-width: 1200px) {
    font-size: 3rem;
  }
`

const SubHeading = styled.h3`
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 15px;
  font-weight: 500;

  @media (min-width: 576px) {
    font-size: 1.75rem;
  }

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`

const Vergleiche = () => {
  return (
    <Layout title="Vergleiche">
      <Container>
        <Row>
          <Col>
            <SectionHeading border center>
              Unsere CBD Ratgeber
            </SectionHeading>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col md={6} xl={5}>
            <SubHeading>CBD Öle</SubHeading>
            <p>
              <strong>CBD Öl</strong> ist das wohl bekannteste CBD Produkt und
              erfährt immer größerer Beliebtheit.
            </p>
            <LinkCta to="/vergleiche/cbd-oel/">Zum CBD Öl Vergleich</LinkCta>
          </Col>
          <Col md={6} xl={5}>
            <SubHeading>CBD Fruchtgummis</SubHeading>
            <p>
              <strong>CBD Fruchtgummis</strong> sind der neueste Trend: Die
              kleinen Gummibärchen kann man ganz einfach immer und überall
              konsumieren und so von der Wirkung des CBD profitieren.
            </p>
            <p>
              In unserem Test vergleichen wir die 3 besten Produkte auf dem
              Markt ausführlich.
            </p>
            <LinkCta to="/vergleiche/cbd-fruchtgummis/">
              Zum CBD Fruchtgummis Vergleich
            </LinkCta>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Vergleiche
